.kit-type {
  font-size: 1.1em;
  position: relative;
  top: 5px;
}

#consent {
  width: 3rem;
  height: 3rem;
  position: relative;
  top: -1.75em;
}
