span {
  transition: color 1.5s ease;
}

#rhythmLogo {
  width: 270px;
}

#PGLogo {
  width: 400px;
}

#orderButton {
  width: 200px;

  a {
    color: white;
    font-size: 1.25em;

    &:hover {
      text-decoration: none;
    }
  }
}

.ie-warning-message {
  max-width: 900px;
  margin: auto;
  position: relative;
  svg {
    position: absolute;
    left: -1em;
    top: 10px;
    path {
      color: darkorange;
    }
  }
}

.disclaimer {
  font-size: 1.1em;
}

@media only screen and (max-width: 1080px) {
  .ie-warning-message svg {
    display: block;
    margin-left: 50%;
    margin-bottom: 5px;
    position: relative;
    top: 0px;
    bottom: 0px;
  }
}

@media only screen and (max-width: 455px) {
  .home {
    h3 {
      font-size: 1.25rem;
    }

    img {
      max-width: 100px;
    }
  }
}
