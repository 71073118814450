// Utility Classes
.hide {
    display: none !important;
}

// ID's / Unique Elements
html,
body,
#root,
.App {
    height: 100%;
}

.mainWrapper {
    min-height: calc(100% - 162px);
}

// Rhythm Unique
$rhythm-purple: #181665;
$rhythm-light-purple: #8076a2;
$rhythm-aqua: #0aaae2;
$rhythm-light-blue: #e7f4fb;

* {
    font-family: "Montserrat", sans-serif;
}

.bg-purple {
    background-color: $rhythm-purple;
}

.bg-light-purple {
    background-color: $rhythm-light-purple;
}

.bg-aqua {
    background-color: $rhythm-aqua;
}

.border-info {
    border-color: $rhythm-aqua !important;
}

.border-grey {
    border: 1px solid grey;
}

.bg-light-blue {
    background-color: $rhythm-light-blue;
}

.program-eligibility-warning {
    font-size: 1.5em;
    * {
        color: orange;
    }
}

.section-banner {
    background-color: $rhythm-purple;
    padding: 0.25em 1em;
}

.text-white {
    * {
        color: white !important;
    }
}

.cursor-pointer {
    cursor: pointer !important;
    * {
        cursor: pointer !important;
    }
}

.ls-none {
    list-style: none;
}

.checkbox-large input {
    position: absolute;
    top: -0.15rem;
    left: 0.75rem;
    width: 1.25rem;
    height: 1.25rem;
}

.st-if-disabled {
    input:disabled ~ label {
        text-decoration: line-through;
    }
}

.text-aqua {
    color: $rhythm-aqua !important;
    svg,
    path {
        color: $rhythm-aqua !important;
    }
}

.centered-alert {
    width: 400px;
    margin: 40px auto 0px;
}

button.no-style {
    border: unset;
    background: unset;
}

#nav .btn-link {
    color: $rhythm-purple;
    margin-left: -1px;
}

#nav .btn-link:hover {
    color: $rhythm-aqua;
    text-decoration: none;
}

button:disabled {
    cursor: not-allowed;
}

.split-button {
    a .btn {
        border-radius: 0.25rem 0px 0px 0.25rem;
    }

    .btn.dropdown-toggle {
        border-radius: 0px 0.25rem 0.25rem 0px;
    }
}

.circle {
    border-radius: 50%;
    display: block;
    margin: 0;
    overflow: hidden;
    position: relative;

    .circle-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.filters {
    fieldset {
        font-size: 0.95em;
        border: 1px solid lightgrey;
        legend {
            font-size: 1.2em;
            max-width: max-content;
        }
        .gene-filters {
            border-left: 1px solid lightgrey;
            .gene-grid {
                display: grid;
                grid-auto-flow: column;
                grid-gap: 1px;
                grid-template-rows: repeat(16, auto);
            }
        }
    }
}

input:focus::-webkit-input-placeholder {
    color: transparent;
}

@media only screen and (max-width: 1200px) {
    .filters fieldset .gene-filters .gene-grid {
        grid-template-rows: repeat(20, auto);
    }
}

@media only screen and (max-width: 768px) {
    .filters fieldset .gene-filters .gene-grid {
        grid-template-rows: repeat(30, auto);
    }
}

@media only screen and (max-width: 490px) {
    .filters fieldset .gene-filters .gene-grid {
        grid-template-rows: repeat(40, auto);
    }
}

@media only screen and (max-width: 455px) {
    html,
    body {
        font-size: 14px;
    }
}

@media only screen and (max-width: 360px) {
    .filters fieldset .gene-filters .gene-grid {
        grid-template-rows: repeat(45, auto);
    }
}

@media only screen and (max-width: 300px) {
    .filters fieldset .gene-filters .gene-grid {
        display: block;
        div {
            width: 100%;
        }
    }
}

/* Print CSS */
@media print {
    ::placeholder {
        color: transparent !important;
    }

    input[value=""]::-webkit-datetime-edit {
        color: transparent !important;
    }

    .page-break-avoid {
        page-break-inside: avoid;
    }

    .page-break-after {
        page-break-after: always;
    }
}
