// Rhythm specific styles
$rhythm-purple: #181665;
$rhythm-light-purple: #8076a2;
$rhythm-aqua: #0aaae2;
$rhythm-light-blue: #e7f4fb;

#nav {
  background-color: $rhythm-light-blue;

  .navbar-brand {
    img {
      width: 200px;
    }
  }

  .navbar-nav {
    a {
      color: #181665;

      &:hover {
        color: $rhythm-aqua;
        text-decoration: none;
      }

      &.selected {
        color: $rhythm-aqua;
      }
    }

    button:not(.btn-link) {
      background-color: $rhythm-purple;
      border-color: $rhythm-purple;
    }
  }

  #profilePicture,
  #profilePicturePlaceholder {
    height: 40px;
    width: 40px;
  }
}
