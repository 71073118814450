$rhythm-purple: #181665;
$rhythm-light-purple: #8076a2;
$rhythm-aqua: #0aaae2;
$rhythm-light-blue: #e7f4fb;

.progress {
  border-radius: 10px;

  .progress-bar {
    border-radius: inherit;
    background-color: $rhythm-aqua;
    transition: width 1s;
  }
}

.search-bar {
  display: flex;
  .search-label {
    flex-grow: 1;
  }
}

/* see: https://stackoverflow.com/a/26983473 */
table {
  /* override Bootstrap's width */
  width: inherit !important;
  .dropdown-menu {
    font-size: 14px;
    padding: 0.5rem 1rem;
    min-width: 145px;
  }
}

.react-bootstrap-table-pagination {
  margin-right: 0;
  .react-bootstrap-table-pagination-list {
    margin-right: 0;
    padding-right: 0;
    .pagination {
      justify-content: right;
    }
  }
}


@media only screen and (min-width: 800px) {
  table {
    width: 100% !important;
  }
}
