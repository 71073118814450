$rhythm-purple: #181665;
$rhythm-light-purple: #8076a2;
$rhythm-aqua: #0aaae2;
$rhythm-light-blue: #e7f4fb;

* {
    color: $rhythm-purple;
}

h4 {
    color: white;
    background-color: $rhythm-purple;
    font-size: 1.35rem;
}

.rythm-header {
    padding: 8px 0;
    background-color: $rhythm-purple;

    h4 {
        font-weight: lighter;
    }

    h1,
    h2,
    h3,
    h4 {
        color: white;
    }
}

.consent-form {
    width: 120%;
    position: relative;
    left: -10%;
}

.text-aqua {
    color: $rhythm-aqua;
}

.bg-light-blue {
    background-color: $rhythm-light-blue;
}

.bg-light-purple {
    background-color: $rhythm-light-purple;
}

.highlighted-row {
    margin: 0 -10px 1em;
    padding: 0 10px;
}

.col-barcode {
    max-width: 400px;
}

.age-of-onset-unknown {
    margin-top: 32px;
}

.signature {
    border-bottom: 1px solid black;
    height: 35px;
    margin-bottom: 1em;

    &::after {
        content: "(Signature Required - Please print and sign)";
        position: absolute;
        top: 67px;
        font-size: 14px;
        font-style: italic;
        color: red;
    }
}

.fa-file-pdf {
    color: red;

    path {
        color: red;
    }
}

.react-pdf__Page__textContent {
    display: none !important;
    overflow: auto;
}

.progress-bar-step {
    background: white;
    border-radius: 50%;

    &.active {
        animation: pulse 2s 2;
    }

    &.completed {
        color: $rhythm-aqua;

        img,
        svg {
            path {
                color: $rhythm-aqua;
            }
        }
    }

    img,
    svg {
        font-size: 1.5em;
    }

    p {
        position: absolute;
        font-size: 14px;
    }
}

.programEligibilityCriteriaCheckbox {
    .form-check-input {
        width: 1.25rem;
        height: 1.25rem;
    }
    .form-check-label {
        font-size: 1.1rem;
        margin: 0.15rem 0.5rem 0.25rem 0.5rem;
    }
}

#sendReportToProviderGroup {
    right: 5px;
}

#printConfirmation {
    height: 25px;
    width: 25px;
    margin-right: 1em;
}

.font-weight-bold-inherit * {
    font-weight: bold !important;
}

#spinnerOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
    > div {
        margin: 17em auto 0 auto;
        width: 4em;
        display: block;
    }
}

#reports {
    .react-bootstrap-table > table {
        border: solid darkgrey 2px;
        > thead th {
            border-bottom: solid darkgrey 2px;
        }
        .report-row {
            border-top: solid darkgrey 2px;
        }
        .reported-variant-row {
            background-color: rgba(0, 0, 0, 0.05);
            table {
                background-color: white;
            }
        }
        th.expand-cell-header,
        td.expand-cell {
            width: 2.7em;
            cursor: pointer;
        }
    }
}

// Animations --------------------------------------
// Pulse animation for Step's circle in active state
@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(33, 131, 221, 0.4);
    }

    70% {
        box-shadow: 0 0 0 8px rgba(33, 131, 221, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(33, 131, 221, 0);
    }
}

// Print Styles -------------------------------------
@media print {
    // Disable form validation styles for printing
    .form-control.is-valid:focus,
    .was-validated :valid.form-control {
        border-color: inherit !important;
        background-image: inherit !important;
        box-shadow: inherit !important;
        padding-right: inherit !important;
    }

    label {
        color: inherit !important;
    }

    .rythm-header {
        h2 {
            font-size: 1.75rem;
        }
    }

    .col-barcode {
        position: absolute;
        right: 0;
        top: 35px;
    }

    .generic-footer {
        position: relative;
        height: 300px;

        img {
            position: absolute;
            bottom: 0;
        }
    }

    .react-pdf__Document {
        margin-left: -75px !important;
        break-before: always;
        break-after: always;
    }

    section {
        break-inside: avoid;
    }
}
