.form {
  margin: 0px auto;
  width: 460px;
  text-align: left;
  padding: 35px 40px;

  h1 {
    font-size: 18px;
  }

  a {
    cursor: pointer;
    color: rgb(10, 170, 226);
  }

  sup {
    margin-left: 5px;
  }

  span {
    transition: color 1.5s ease;
  }

  label {
    font-size: 14px;
  }

  input {
    display: block;
    width: 100%;
    padding: 16px;
    font-size: 14px;
    color: #152939;
    background-image: none;
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    box-sizing: border-box;
    margin-bottom: 10px;
    height: 55px;
  }

  div:not(.alert) {
    button {
      min-width: 153px;
      display: inline-block;
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.42857143;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      touch-action: manipulation;
      cursor: pointer;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-image: none;
      color: #ffffff;
      background-color: rgb(10, 170, 226);
      border-color: #ccc;
      text-transform: uppercase;
      padding: 14px 0;
      letter-spacing: 1.1px;
      border: none;
      border-radius: 0;

      &:hover {
        opacity: 0.8;
        background-color: rgb(10, 170, 226);
      }
    }
  }

  .hint {
    font-size: 12px;
  }

  .section-or-line {
    border-bottom: 1px solid #c4c4c4;
    position: relative;

    .or {
      background: white;
      position: absolute;
      left: calc(50% - 50px / 2);
      top: -11px;
      width: 50px;
      text-align: center;
    }
  }

  .section-footer {
    p {
      font-size: 14px;
      position: relative;
      top: 12px;
    }
  }

  #googleSignInButton {
    background-color: #4285f4;
    font-family: Montserrat;
    border: 1px solid #4285f4;
    color: #ffffff;
    cursor: pointer;
    position: relative;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 0;
    font-size: 14px;
    box-sizing: content-box;
    text-transform: none;
    letter-spacing: normal;

    .sign-in-button-icon {
      height: 100%;
      width: 57px;
      background-color: #ffffff;
      border-radius: 4px 0 0 4px;
      padding: 10px;
      position: absolute;
      left: 0;

      img {
        width: 35px;
      }
    }

    .sign-in-button-content {
      color: #ffffff;
      text-align: center;
      display: block;
      padding: 18px 0;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      font-size: 14px;
    }
  }

  .sign-up-requirements {
    ul {
      padding-left: 25px;
      li {
        list-style: none;
        font-size: 15px;
        span {
          position: relative;
          &.success {
            color: #19bf00;
            svg,
            path {
              color: #19bf00;
            }
          }
          &.error {
            color: #df3312;
            svg,
            path {
              color: #df3312;
            }
          }
          svg {
            position: absolute;
            top: 1px;
            left: -20px;
          }
        }
      }
    }
  }
}
