// Rhythm specific styles
$rhythm-purple: #181665;
$rhythm-light-purple: #8076a2;
$rhythm-aqua: #0aaae2;
$rhythm-light-blue: #e7f4fb;

#footer {
  clear: both;
}

#topFooter {
  background-color: $rhythm-light-blue;

  span,
  strong,
  small {
    color: $rhythm-purple;
  }

  img {
    max-width: 225px;
    width: 100%;
  }
}

#subFooter {
  background-color: $rhythm-purple;
  font-size: 15px;

  span,
  strong,
  small {
    color: white;
  }
}
