#reports {
    .file-explorer-task-bar {
        svg {
            font-size: 1.25em;
            cursor: pointer;

            &.disabled {
                color: grey !important;
                cursor: auto;

                path {
                    color: grey !important;
                    fill: grey !important;
                }
            }
        }
    }

    .pagination {
        justify-content: end;
    }
}